import userActions from './user'
import pipelineActions from './pipeline'
import agmActions from './agm'
import testActions from './test'
import contractorActions from './contractor'
import clientActions from './client'
import notifiedActions from './notified';
export default {
  ...userActions,
  ...pipelineActions,
  ...agmActions,
  ...testActions,
  ...contractorActions,
  ...clientActions,
  ...notifiedActions
}
