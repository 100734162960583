<template>
  <div>
    <b-table show-empty stacked="md" :items="agms" :fields="fields">
      <template v-slot:cell(geolocation)="data">
        <span v-if="data.item.latitude && data.item.longitude">
          ({{ data.item.latitude }}, {{ data.item.longitude }})
        </span>
      </template>
      <template v-slot:cell(notifications)="row">
        <b-form-checkbox disabled :checked="row.item.notifications_enabled">
          SMS Notifications
        </b-form-checkbox>
      </template>
      <template v-slot:cell(actions)="row">
        <!-- slot data -->
        <b-button
          size="sm"
          variant="info"
          @click="editAGM(row.item)"
          class="mr-1"
          ><i class="fa fa-pencil"
        /></b-button>
        <b-button size="sm" variant="danger" @click="deleteAGM(row.item)"
          ><i class="fa fa-close"
        /></b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { EventBus } from '@/main';

export default {
  props: {
    agms: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      fields: [
        { key: 'marker_mile_number', label: 'AGM #s', sortable: true },
        { key: 'site_description', label: 'Site Description' },
        { key: 'run_distance', label: 'Run Distance (ft)' },
        { key: 'mp', label: 'MP' },
        { key: 'diameter', label: 'Distance' },
        { key: 'description_of_location', label: 'Description of Location' },
        { key: 'geolocation', label: 'Geolocation' },
        { key: 'actions', label: 'Actions' },
        { key: 'notifications', label: 'Notifications' },
      ],
    };
  },
  methods: {
    editAGM(agm) {
      EventBus.$emit('createAGMModal.open', agm);
    },
    deleteAGM(agm) {
      EventBus.$emit('deleteAGMModal.open', agm);
    },
  },
};
</script>
