<template>
  <div>
    <div class="app-embed-wrapper">
      <p>
        If a window does not open click
        <a :href="embedUrl" @click="openMobileApp">
          Here
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      embedUrl: process.env.VUE_APP_MOBILE_APP_URL,
      opened: false
    };
  },
  methods: {
    openMobileApp(e) {
      e && e.preventDefault();
      window.open(
        this.embedUrl,
        'targetWindow',
        `toolbar=no,
                                    location=no,
                                    status=no,
                                    menubar=no,
                                    scrollbars=yes,
                                    resizable=yes,
                                    width=400,
                                    height=800`
      );
      return false;
    }
  },
  watch: {
    isOpen(open) {
      open && !this.opened && this.openMobileApp();
      this.opened = true;
    }
  }
};
</script>

<style scoped>
.app-embed-wrapper {
  max-width: 400px;
  width: 100%;
  margin: auto;
}
.app-embed {
  width: 100%;
  height: 600px;
}
</style>
