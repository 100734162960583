import GlobalComponents from './globalComponents';
import 'es6-promise/auto';
import VueResource from 'vue-resource';
import BootstrapVue from 'bootstrap-vue';

//css assets
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/css/index.css';

export default {
  install(Vue) {
    Vue.use(VueResource);
    Vue.use(GlobalComponents);
    Vue.use(BootstrapVue);
  }
};
