<template>
  <b-modal
    class="createContractorModal"
    :title="title"
    centered
    size="lg"
    ref="createContractorModal"
    ok-title="Save Contractor"
    cancel-title="Close"
    @open="open"
    @ok="handleOk"
  >
    <b-alert show variant="light">* denotes a required field</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" class="px-4">
      <b-form-row>
        <b-col>
          <b-form-group label="CID (6 digits)*">
            <b-form-input
              v-model="form.cid"
              type="number"
              required
              placeholder=""
              :state="!form.error.cid"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Full Name *">
            <b-form-input
              v-model="form.full_name"
              required
              placeholder=""
              :state="!form.error.full_name"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Email *">
            <b-form-input
              v-model="form.email"
              type="email"
              required
              placeholder=""
              :state="!form.error.email"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Phone Number *">
            <b-form-input
              v-model="form.phone"
              type="number"
              required
              placeholder=""
              :state="!form.error.phone"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Pin *">
            <b-form-input
              v-model="form.pin"
              required
              placeholder=""
              :state="!form.error.pin"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
    <b-loading :loading="processing" />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
// import mixpanel from 'mixpanel-browser';
// mixpanel.init('e5412e4ff19e4e1286f5e27b8dac051d', { debug: true });

export default {
  data() {
    return {
      processing: false,
      isModeCreate: true,
      initial: {
        full_name: '',
        email: '',
        phone: '',
        pin: '',
        cid: '',
      },
      form: {
        full_name: '',
        email: '',
        phone: '',
        pin: '',
        cid: '',
        error: {
          full_name: false,
          email: false,
          pin: false,
          cid: false,
        },
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.validate();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['error', 'loading', 'agms']),
    title() {
      return this.isModeCreate ? 'Add Contractor' : 'Edit Contractor';
    },
    agms_options() {
      return [
        { value: null, text: 'Please select one below' },
        ...this.agms.map((agm) => ({
          value: agm.id,
          text: agm.marker_mile_number + ' ' + agm.site_description,
        })),
      ];
    },
  },
  methods: {
    open(payload) {
      if (payload) {
        Object.keys(payload).forEach((key) => {
          if (key === 'phone') {
            this.initial['phone'] = payload['phone'].slice(2);
          } else {
            this.initial[key] = payload[key];
          }
        });
        this.isModeCreate = false;
      } else {
        Object.keys(this.initial).forEach((key) => {
          this.initial[key] = '';
        });
        this.initial['cid'] = ('000000' + Math.random() * 999999).slice(-6);
        this.isModeCreate = true;
      }
      this.resetFormFields();
      this.$refs.createContractorModal.show();
    },
    close() {
      this.$refs.createContractorModal.hide();
    },

    validatePhone() {
      const phoneUtil = PhoneNumberUtil.getInstance();
      let parsedPhone;
      try {
        parsedPhone = phoneUtil.parseAndKeepRawInput(this.form.phone, 'US');
      } catch (e) {
        return false;
      }

      return phoneUtil.isValidNumber(parsedPhone);
    },
    validate() {
      this.form.error.full_name = !this.form.full_name;
      this.form.error.email =
        !this.form.email || !this.form.email.validateEmail();
      this.form.error.phone = !this.form.phone || !this.validatePhone();
      this.form.error.pin = !this.form.pin;
      this.form.error.cid =
        !this.form.cid || this.form.cid.length != 6 || +this.form.cid > 999999;
    },
    hasError() {
      return Object.keys(this.form.error).some((key) => this.form.error[key]);
    },
    handleOk(e) {
      e.preventDefault();
      this.onSubmit();
    },
    onSubmit() {
      this.onSubmitForm();
    },
    onCancel() {
      this.close();
    },
    onReset() {
      this.resetFormFields();
    },
    resetFormFields() {
      this.form = {
        full_name: '',
        email: '',
        phone: '',
        pin: '',
        cid: '',
        error: {
          full_name: false,
          email: false,
          phone: false,
          pin: false,
          cid: false,
        },
        ...this.initial,
      };

      this.processing = false;
    },
    onSubmitForm(e) {
      e && e.preventDefault();
      if (!this.hasError() && !this.processing && !this.loading) {
        this.processing = true;

        const phoneUtil = PhoneNumberUtil.getInstance();

        const parsedPhone = phoneUtil.parse(this.form.phone, 'US');

        const parsedNumber = phoneUtil.format(
          parsedPhone,
          PhoneNumberFormat.E164
        );

        this.form.phone = parsedNumber;

        const { ...payload } = this.form;
        // mixpanel.track('Contractor Created');
        // mixpanel.alias(payload.cid);
        this.$store
          .dispatch(
            this.isModeCreate ? 'createContractor' : 'updateContractor',
            payload
          )
          .finally(() => {
            this.processing = false;
            this.close();
          });
      }
    },
  },
  created() {
    this.resetFormFields();
  },
};
</script>
<style>
.createContractorModal .modal-header {
  border-bottom: 0 none;
}
.createContractorModal .form-control {
  border: solid 1px #ccc;
}
.createContractorModal .form-actions .btn {
  min-width: 100px;
}
</style>
