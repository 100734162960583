import { db } from '@/main';
import { firestoreAction } from 'vuexfire';

export default {
  setContractorsRef: firestoreAction(
    ({ bindFirestoreRef, state }, { ref, callback }) => {
      if (state.contractors !== null) {
        callback(false);
        return;
      }
      bindFirestoreRef('contractors', ref).then(() => {
        callback();
      });
    }
  ),

  createContractor({ commit, getters }, payload) {
    commit('setLoading', true);
    const contractorRef = db.collection('contractors').doc();
    const { ...docData } = payload;
    return contractorRef
      .set({
        ...docData,
        id: contractorRef.id,
      })
      .then(() => {
        commit('setLoading', false);
        return Promise.resolve();
      });
  },

  updateContractor({ commit }, payload) {
    commit('setLoading', true);
    const { id: contractorID, ...docData } = payload;
    const contractorRef = db.collection('contractors').doc(contractorID);
    return contractorRef
      .update({
        full_name: docData.full_name,
        email: docData.email,
        phone: docData.phone,
        pin: docData.pin,
        cid: docData.cid,
      })
      .then(() => {
        commit('setLoading', false);
        return Promise.resolve();
      });
  },

  deleteContractor({ commit }, payload) {
    commit('setLoading', true);
    return db
      .collection('contractors')
      .doc(payload.id)
      .delete()
      .then(() => {
        commit('setLoading', false);
        return Promise.resolve();
      });
  },
};
