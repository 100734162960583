/* eslint-disable */
import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

import firebaseConfig from './firebaseConfig';
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const functions = firebase.functions();
export const auth = firebase.auth();

if (
  window.location.href.includes('http://localhost') &&
  `${process.env.VUE_APP_USE_EMULATOR}` !== 'false'
) {
  // eslint-disable-next-line no-console
  console.log('EMULATOR!');
  auth.useEmulator('http://localhost:9099');
  functions.useEmulator('localhost', 5001);
  db.useEmulator('0.0.0.0', 8080);
}

// eslint-disable-next-line no-console
console.log('not emulator necessarily', process.env);

Vue.config.productionTip = false;

import plugins from './plugins';
Vue.use(plugins);

// App
import App from './App.vue';
import router from './router/index';
import { store } from './store';

/**
 * Event Transport Layer for the Modals
 */
export const EventBus = new Vue();

/* eslint-disable no-new */

let app;

auth.onAuthStateChanged((firebaseUser) => {
  app = new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App),
    created() {
      // firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        store.dispatch('autoSignIn', firebaseUser);
      }
      // });
    },
  });
});

String.prototype.validateEmail = function () {
  // eslint-disable-next-line
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(this.toLowerCase());
};
