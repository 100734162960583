import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const initialState = () => {
  return {
    appTitle: 'Pipeline Admin',
    user: null,
    error: null,
    loading: false,
    pipelines: null,
    agms: null,
    tests: null,
    contractors: null,
    clients: null,
    activePipeline: null,
    activePipelineData: {
      agms: [],
      tests: [],
      contractors: [],
      clients: [],
      phones: []
    },
    activeTest: null,
    activeTestData: {
      contractors: []
    },
    activeTestAgms: []
  };
};

export const store = new Vuex.Store({
  state: initialState,
  mutations,
  actions,
  getters
});
