<template>
  <b-card class="page-contractors" header="Clients">
    <b-table show-empty stacked="md" :items="clients" :fields="fields">
      <template v-slot:cell(agm)="data">
        {{ data.value.marker_mile_number }} {{ data.value.site_description }}
      </template>
      <template v-slot:cell(modify)="row">
        <b-button
          size="sm"
          variant="info"
          @click="editClient(row.item)"
          class="mr-1"
          ><i class="fa fa-pencil"
        /></b-button>
        <b-button size="sm" variant="danger" @click="deleteClient(row.item)"
          ><i class="fa fa-close"
        /></b-button>
      </template>
    </b-table>
    <b-row class="text-center mt-4">
      <b-col>
        <b-button
          variant="info"
          :disabled="processing || loading"
          @click="addNewClient"
          >Add New Client</b-button
        >
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';

import { EventBus } from '@/main';

export default {
  components: {},
  data() {
    return {
      processing: false,
      fields: [
        { key: 'email', label: 'Email' },
        { key: 'company_name', label: 'Company Name' }
        // , { key: 'modify', label: 'Modify' } // No modifying for now since password constraints etc
      ]
    };
  },
  computed: {
    ...mapGetters(['loading', 'pipeline', 'test', 'clients'])
  },
  methods: {
    addNewClient() {
      EventBus.$emit('createClientModal.open');
    },
    editClient(client) {
      EventBus.$emit('createClientModal.open', client);
    },
    deleteClient(client) {
      EventBus.$emit('deleteClientModal.open', client);
    }
  }
};
</script>
