<template>
  <div>
    <router-view></router-view>
    <div class="modals">
      <create-agm-modal
        @create="createAGM"
        @update="updateAGM"
        ref="createAGMModal"
      />
      <delete-agm-modal @delete="deleteAGM" ref="deleteAGMModal" />
      <create-test-modal ref="createTestModal" />
      <UploadAGMModal ref="uploadAGMModal"> </UploadAGMModal>
    </div>
  </div>
</template>

<script>
import { EventBus, db } from '@/main';

// Modals
import CreateAGMModal from '../../modals/CreateAGMModal';
import DeleteAGMModal from '../../modals/DeleteAGMModal';
import CreateTestModal from '../../modals/CreateTestModal';
import UploadAGMModal from '../../modals/UploadAGMModal';

export default {
  props: ['pipeline_id'],
  components: {
    'create-agm-modal': CreateAGMModal,
    'delete-agm-modal': DeleteAGMModal,
    CreateTestModal,
    UploadAGMModal
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('setActivePipeline', {
        id: vm.pipeline_id,
        callback: () => {}
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    next(() => {
      this.$store.dispatch('unsetActivePipeline', {
        callback: () => {}
      });
    });
  },
  created: function() {
    EventBus.$on('createAGMModal.open', payload => {
      if (this.$refs.createAGMModal) {
        this.$refs.createAGMModal.open(payload);
      }
    });
    EventBus.$on('createAGMModal.close', payload => {
      if (this.$refs.createAGMModal) {
        this.$refs.createAGMModal.close();
      }
    });
    EventBus.$on('deleteAGMModal.close', payload => {
      if (this.$refs.deleteAGMModal) {
        this.$refs.deleteAGMModal.close();
      }
    });
    EventBus.$on('deleteAGMModal.open', payload => {
      if (this.$refs.deleteAGMModal) {
        this.$refs.deleteAGMModal.open(payload);
      }
    });
    EventBus.$on('createTestModal.open', payload => {
      if (this.$refs.createTestModal) {
        this.$refs.createTestModal.open(payload);
      }
    });
    EventBus.$on('uploadAgmModal.open', payload => {
      if (this.$refs.uploadAGMModal) {
        this.$refs.uploadAGMModal.open(payload);
      }
    });
  },
  methods: {
    createAGM(payload) {
      EventBus.$emit('createAGM.submit', payload);
    },
    updateAGM(payload) {
      EventBus.$emit('updateAGM.submit', payload);
    },
    deleteAGM(payload) {
      EventBus.$emit('deleteAGM.submit', payload);
    }
  },
  beforeDestroy: function() {
    EventBus.$off([
      'createAGMModal.open',
      'deleteAGMModal.open',
      'createTestModal.open',
      'uploadAgmModal.open',
      'createAGMModal.close'
    ]);
  }
};
</script>

<style>
</style>
