import { vuexfireMutations } from 'vuexfire';
import { initialState } from '../';

export default {
  setError(state, payload) {
    state.error = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  updateUser(state, payload) {
    state.user = {
      ...state.user,
      ...payload
    };
  },
  unsetActivePipeline(state) {
    state.activePipeline = null;
    state.activePipelineData = {
      agms: [],
      tests: [],
      contractors: [],
      clients: [],
      phones: []
    };
  },
  unsetActiveTest(state) {
    state.activeTest = null;
    state.activeTestData = {
      contractors: []
    };
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  ...vuexfireMutations
};
