<template>
  <b-card class="page-agms" header="Above Ground Markers">
    <p class="text-muted">
      <small>
        Note: Editing / adding / deleting AGMs does not affect tests that have
        already been created. <br />
        When a test is created, these AGMs are copied. From that point on, edit
        the AGMs under that test</small
      >
    </p>
    <AgmsTable :agms="agms"> </AgmsTable>
    <b-row class="text-center mt-4">
      <b-col>
        <b-button
          variant="info"
          :disabled="processing || loading"
          @click="addNewAGM"
          >Add Marker</b-button
        >
        <b-button
          @click="uploadAGMs"
          variant="secondary"
          :disabled="processing || loading"
          >Upload Markers</b-button
        >
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex';

import { EventBus } from '@/main';
import AgmsTable from '@/components/AgmsTable';
export default {
  components: {
    AgmsTable
  },
  data() {
    return {
      processing: false
    };
  },
  computed: {
    ...mapGetters(['loading', 'pipeline', 'agms'])
  },
  methods: {
    addNewAGM() {
      EventBus.$emit('createAGMModal.open');
    },
    uploadAGMs() {
      EventBus.$emit('uploadAgmModal.open');
    },
    persistAGM(payload, action) {
      this.$store.dispatch(action, payload).finally(() => {
        this.processing = false;
        EventBus.$emit('createAGMModal.close');
      });
    }
  },
  mounted() {
    EventBus.$on('createAGM.submit', payload => {
      this.persistAGM(payload, 'createAGM');
    });

    EventBus.$on('updateAGM.submit', payload => {
      this.persistAGM(payload, 'updateAGM');
    });

    EventBus.$on('deleteAGM.submit', payload => {
      this.$store.dispatch('deleteAGM', payload).finally(() => {
        EventBus.$emit('deleteAGMModal.close');
      });
    });
  },
  beforeDestroy() {
    EventBus.$off(['createAGM.submit', 'updateAGM.submit', 'deleteAGM.submit']);
  }
};
</script>
<style>
</style>
