<template>
  <b-modal
    class="uploadAGMSModal"
    title="Upload AGMS from Spreadsheet"
    centered
    size="lg"
    ref="uploadAGMSModal"
    :ok-disabled="!uploadedAgms || uploadedAgms.length === 0"
    @open="open"
    @ok="handleOk"
  >
    <b-form-file
      v-show="!loading"
      v-model="file"
      accept=".xlsx"
      :state="Boolean(file)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      ref="fileupload"
    ></b-form-file>
    <div v-show="loading" class="text-center">
      <p>Uploading AGMS... please don't leave the page until this finishes or some AGMs won't be uploaded.</p>
      <b-spinner></b-spinner>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import AgmUploadTransformer from '../services/AgmUploadTransformer';

export default {
  data() {
    return {
      file: null,
      uploadedAgms: null,
    };
  },
  watch: {
    file(file) {
      if (!file) {
        return;
      }
      let reader = new FileReader();
      reader.onload = (event) => {
        (async () => {
          try {
            this.uploadedAgms = await AgmUploadTransformer(event.target.result);
          } catch (err) {
            this.$refs.fileupload.reset();
            alert(`Error with data upload! ${err.message}`);
            this.file = null;
          }
        })();
      };
      reader.readAsArrayBuffer(file);
    },
  },
  computed: {
    ...mapGetters(['loading']),
  },
  methods: {
    open(payload) {
      this.$refs.uploadAGMSModal.show();
    },
    close() {
      this.$refs.uploadAGMSModal.hide();
    },
    handleOk(e) {
      e.preventDefault();
      e.cancel && e.cancel();
      // Store dispatch


      this.$store
        .dispatch('createAGMS', this.uploadedAgms)
        .then(() => {
          try {
            this.close();
            // eslint-disable-next-line no-empty
          } catch (err) {}
        })
        .catch((err) => {
          alert(`Error uploading agm! ${err.message}`);
        })
    },
  },
};
</script>