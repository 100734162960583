import { db } from '@/main';
import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from '../../firebaseConfig';

const auth = firebase.initializeApp(firebaseConfig, 'Secondary').auth();

if (
  window.location.href.includes('http://localhost') &&
  `${process.env.VUE_APP_USE_EMULATOR}` !== 'false'
) {
  auth.useEmulator('http://localhost:9099');
}

import { firestoreAction } from 'vuexfire';
export default {
  setClientsRef: firestoreAction(
    ({ bindFirestoreRef, state }, { ref, callback }) => {
      if (state.clients !== null) {
        callback(false);
        return;
      }
      bindFirestoreRef('clients', ref).then(() => {
        callback();
      });
    }
  ),

  createClient({ commit, getters }, payload) {
    commit('setLoading', true);
    const clientRef = db.collection('clients').doc();
    const { email, password, confirm_password, ...docData } = payload;
    return db
      .runTransaction(async (transact) => {
        const user = await auth.createUserWithEmailAndPassword(email, password);
        transact.set(clientRef, {
          ...docData,
          email,
          id: clientRef.id,
          user_id: user.user.uid,
        });
      })
      .then(() => {
        commit('setLoading', false);
      });
  },
};
