<template>
  <b-card class="page-contractors" header="Contractors">
    <b-table show-empty stacked="md" :items="contractors" :fields="fields">
      <template v-slot:cell(agm)="data">
        {{ data.value.marker_mile_number }} {{ data.value.site_description }}
      </template>
      <template v-slot:cell(modify)="row">
        <b-button
          size="sm"
          variant="info"
          @click="editContractor(row.item)"
          class="mr-1"
          ><i class="fa fa-pencil"
        /></b-button>
        <b-button size="sm" variant="danger" @click="deleteContractor(row.item)"
          ><i class="fa fa-close"
        /></b-button>
      </template>
    </b-table>
    <b-row class="text-center mt-4">
      <b-col>
        <b-button
          variant="info"
          :disabled="processing || loading"
          @click="addNewContractor"
          >Add Contractor</b-button
        >
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex';

import { EventBus } from '@/main';

export default {
  data() {
    return {
      processing: false,
      fields: [
        { key: 'cid', label: 'CID' },
        { key: 'full_name', label: 'Full Name' },
        { key: 'email', label: 'Email' },
        { key: 'pin', label: 'Pin' },
        { key: 'modify', label: 'Modify' },
        { key: 'pipelines', label: 'Pipelines' }
      ]
    };
  },
  computed: {
    ...mapGetters(['loading', 'pipeline', 'test', 'contractors'])
  },
  methods: {
    addNewContractor() {
      EventBus.$emit('createContractorModal.open');
    },
    editContractor(contractor) {
      EventBus.$emit('createContractorModal.open', contractor);
    },
    deleteContractor(contractor) {
      EventBus.$emit('deleteContractorModal.open', contractor);
    }
  }
};
</script>
<style>
</style>
