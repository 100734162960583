import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";

import routes from "./routes";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  let currentUser = firebase.auth().currentUser;
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (!currentUser && requiresAuth) {
    next({
      path: '/login'
    });
    return;
  }

  if (currentUser && (to.name === 'Login' || to.name === 'Signup' || to.name === 'Reset Password')) {
    next({
      path: '/pipelines'
    });
    return;
  }

  next();
});

export default router;
