<template>
  <b-modal
    class="createTestModal"
    title="Add Run"
    centered
    size="lg"
    ref="createTestModal"
    ok-title="Add Run"
    cancel-title="Close"
    @open="open"
    @ok="handleOk"
  >
    <b-alert show variant="light">* denotes a required field</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" class="px-4">
      <b-form-row>
        <b-col>
          <b-form-group label="Run Description *">
            <b-form-textarea
              v-model="form.description"
              required
              rows="3"
              :state="form.description.length > 0"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Run Date *">
            <b-form-input
              type="date"
              v-model="form.created"
              required
              :state="!!form.created"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Healthy speed Max*">
            <b-form-select
              :state="!!form.max_healthy_speed"
              required
              v-model="form.max_healthy_speed"
            >
              <b-form-select-option style="color: red" :value="null" disabled
                >Please select a speed</b-form-select-option
              >
              <b-form-select-option
                v-for="speed in speedOptions"
                :value="speed.value"
                :key="speed.value"
                >{{ speed.label }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Healthy speed Min *">
            <b-form-select
              :state="!!form.min_healthy_speed"
              required
              v-model="form.min_healthy_speed"
            >
              <b-form-select-option style="color: red" :value="null" disabled
                >Please select a speed</b-form-select-option
              >
              <b-form-select-option
                v-for="speed in speedOptions"
                :value="speed.value"
                :key="speed.value"
                >{{ speed.label }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Maximum speed *">
            <b-form-select
              :state="!!form.max_speed"
              required
              v-model="form.max_speed"
            >
              <b-form-select-option style="color: red" :value="null" disabled
                >Please select a speed</b-form-select-option
              >
              <b-form-select-option
                v-for="speed in speedOptions"
                :value="speed.value"
                :key="speed.value"
                >{{ speed.label }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
    <b-loading :loading="processing" />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      speedOptions: Array.from(Array(100)).map((_, idx) => {
        const mph = (idx + 1) / 4;
        return {
          key: mph,
          value: mph,
          label: `${mph.toFixed(2)} mph`,
        };
      }),
      processing: false,
      form: {
        description: '',
        created: null,
        reverse_order: null,
        max_speed: null,
        max_healthy_speed: null,
        min_healthy_speed: null,
        error: {
          description: false,
          created: true,
        },
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.validate();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['error', 'loading']),
  },
  methods: {
    open() {
      this.resetFormFields();
      this.$refs.createTestModal.show();
    },
    close() {
      this.$refs.createTestModal.hide();
    },
    validate() {
      this.form.error.description = !this.form.description;
      this.form.error.max_speed = this.form.max_speed === null;
      this.form.error.min_healthy_speed = this.form.min_healthy_speed === null;
      this.form.error.max_healthy_speed = this.form.max_healthy_speed === null;

      this.form.error.created = !this.form.created;
    },
    hasError() {
      return Object.keys(this.form.error).some((key) => this.form.error[key]);
    },
    handleOk(e) {
      e.preventDefault();
      this.onSubmit();
    },
    onSubmit() {
      this.onSubmitForm();
    },
    onCancel() {
      this.close();
    },
    onReset() {
      this.resetFormFields();
    },
    resetFormFields() {
      this.form = {
        description: '',
        created: moment().format('YYYY-MM-DD'),
        reverse_order: false,
        max_healthy_speed: null,
        max_speed: null,
        min_healthy_speed: null,
        error: {
          description: false,
          created: false,
        },
      };
      this.processing = false;
    },
    onSubmitForm(e) {
      e && e.preventDefault();
      if (!this.hasError() && !this.processing && !this.loading) {
        this.processing = true;
        const {
          description,
          created,
          reverse_order,
          max_healthy_speed,
          max_speed,
          min_healthy_speed,
        } = this.form;
        this.$store
          .dispatch('createTest', {
            description,
            created,
            reverse_order,
            pig_stopped: false,
            max_healthy_speed,
            max_speed,
            min_healthy_speed,
          })
          .finally(() => {
            this.processing = false;
            this.close();
          });
      }
    },
  },
  created() {
    this.resetFormFields();
  },
};
</script>
<style>
.createTestModal .modal-header {
  border-bottom: 0 none;
}
.createTestModal .form-control {
  border: solid 1px #ccc;
}
.createTestModal .form-actions .btn {
  min-width: 100px;
}
</style>
