import { firestoreAction } from 'vuexfire';
import { db, functions } from '@/main';
import includes from 'lodash/includes';

const notifiedActions = {
  /**
   *
   * @param {any} param0
   * @param {Array<any>} phones
   */
  async setNotified({ commit, getters }, phones) {
    commit('setLoading', true);
    try {
      // need to delete all phones not in ph
      const { docs: phoneDocs } = await db
        .collection('pipelines')
        .doc(getters.pipeline.id)
        .collection('notified')
        .get();
      const existingNums = phoneDocs.map(doc => doc.data().phone_number);
      // Any phoneDocs not in phones, remove
      db.runTransaction(async transact => {
        await Promise.all(
          phoneDocs
            .filter(doc => {
              return !includes(phones, doc.data().phone_number);
            })
            .map(doc => transact.delete(doc.ref))
        );

        await Promise.all(
          phones
            .filter(num => !includes(existingNums, num))
            .map(phone => {
              const doc = db
                .collection('pipelines')
                .doc(getters.pipeline.id)
                .collection('notified')
                .doc();

              return transact.set(doc, {
                id: doc.id,
                phone_number: phone
              });
            })
        );
      });
    } catch (err) {}

    commit('setLoading', false);
  }
};

export default notifiedActions;
