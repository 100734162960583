// General
// Auth pages
import AuthLayout from '@/layout/auth/layout.vue';
import PageLayout from '@/layout/page/layout.vue';
import PipelineWrapper from '@/layout/pipeline/wrapper.vue';
import AGMs from '@/pages/AGMs.vue';
import Login from '@/pages/Auth/Login.vue';
import FlipSheet from '@/pages/FlipSheet.vue';
import HomePage from '@/pages/HomePage.vue';
import NotFound from '@/pages/NotFound.vue';
import Tests from '@/pages/Tests.vue';
import TestAgms from '@/pages/TestAgms';
import TestWrapper from '@/layout/test/wrapper';

const routes = [
  {
    path: '/',
    component: PageLayout,
    redirect: 'home',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: HomePage
      },
      {
        path: 'pipelines/:pipeline_id',
        redirect: 'pipelines/:pipeline_id/agms',
        component: PipelineWrapper,
        props: true,
        children: [
          {
            path: 'tests/:test_id',
            name: 'ActiveTest',
            component: TestWrapper,
            props: true,
            children: [
              {
                path: 'agms',
                component: TestAgms
              }
            ]
          },
          {
            path: 'agms',
            name: 'AGMs',
            component: AGMs
          },
          {
            path: 'tests',
            name: 'Tests',
            component: Tests
          }
        ]
      }
    ]
  },
  {
    path: '/',
    name: 'auth',
    component: AuthLayout,
    redirect: 'login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      }
    ]
  },
  {
    path: '/',
    name: 'flip',
    component: AuthLayout,
    children: [
      {
        path: 'flip-sheet',
        name: 'flip',
        component: FlipSheet
      }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
