<template>
  <div>
    <router-view></router-view>
    <div class="modals"></div>
  </div>
</template>

<script>
import { EventBus, db } from '@/main';

// Modals

export default {
  props: ['pipeline_id', 'test_id'],
  components: {},
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.$store.dispatch('setActiveTest', {
        ref: db
          .collection('pipelines')
          .doc(vm.pipeline_id)
          .collection('tests')
          .doc(vm.test_id),
        callback: () => {}
      });
    });
  },
  created: function() {},
  beforeDestroy: function() {
    EventBus.$off([]);
  }
};
</script>

<style>
</style>
