import firebase from 'firebase/app';

import router from '@/router';

export default {
  userSignIn({ commit }, payload) {
    commit('setLoading', true);
    firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then(resp => resp.user.getIdTokenResult())
      .then(({ claims }) => {
        if (claims.role !== "admin") {
          throw new Error('Unauthorized');
        }
        commit('setError', null);
        commit('setLoading', false);
        location.href = '/';
      })
      .catch(error => {
        commit('setError', error.message);
        commit('setLoading', false);
        return firebase.auth().signOut();
      });
  },
  autoSignIn({ commit }, payload) {
    commit('setUser', {
      email: payload.email
    });
  },
  userSignOut({ commit }) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        commit('setUser', null);
        commit('reset');
        router.push('/login');
      });
  }
};
