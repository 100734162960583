<template>
  <div>
    <div class="text-center p-4">
      <b-button-group size="sm">
        <b-button variant="outline-warning" @click="notificationsOnAllAgms">Turn Notifications ON for all AGMS</b-button>
        <b-button variant="outline-danger" @click="notificationsOffAllAgms">Turn Notifications OFF for all AGMS</b-button>
      </b-button-group>
    </div>
    <AgmsTable :agms="pipeAgms">
      <template v-slot:actions="{ row }">
        <div>
          <b-form-checkbox
            @change="notificationsChanged(row.item, $event)"
            :checked="!!notifications[row.item.id]"
          >
            SMS Notifications
          </b-form-checkbox>
          <b-button-group size="sm">
            <b-button variant="info"><i class="fa fa-pencil"/></b-button>
          </b-button-group>
        </div>
      </template>
    </AgmsTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AgmsTable from '@/components/AgmsTable';
import reduce from 'lodash/reduce';

import { EventBus } from '@/main';
export default {
  components: {
    AgmsTable
  },
  computed: {
    ...mapGetters(['loading', 'pipeline', 'pipeAgms']),

    notifications() {
      return reduce(
        this.pipeAgms,
        (result, { id, notifications_enabled }) => ({
          ...result,
          [id]: !!notifications_enabled
        }),
        {}
      );
    }
  },
  data() {
    return {};
  },
  methods: {
    notificationsChanged(item, on) {
      this.$store.dispatch('updateTestAgm', {
        test_id: this.$route.params.test_id,
        payload: {
          ...item,
          notifications_enabled: on
        }
      });
    },

    notificationsOnAllAgms() {
      this.pipeAgms.forEach(agm => {
        this.notificationsChanged(agm, true);
      });
    },

    notificationsOffAllAgms() {
      this.pipeAgms.forEach(agm => {
        this.notificationsChanged(agm, false);
      });
    }
  },
  mounted() {
    EventBus.$on('createAGM.submit', payload => {
      // this.persistAGM(payload, 'createAGM');
      // TODO implement New create AGM endpoint
    });

    EventBus.$on('updateAGM.submit', payload => {
      // this.persistAGM(payload, 'updateAGM');
      this.$store
        .dispatch('updateTestAgm', {
          test_id: this.$route.params.test_id,
          payload
        })
        .then(() => {
          EventBus.$emit('createAGMModal.close');
        });
    });

    EventBus.$on('deleteAGM.submit', payload => {
      this.$store
        .dispatch('deleteTestAgm', {
          test_id: this.$route.params.test_id,
          agm_id: payload.id
        })
        .then(() => {
          EventBus.$emit('deleteAGMModal.close');
        });
    });
  },
  beforeDestroy() {
    EventBus.$off(['CreateAGM.submit', 'UpdateAGM.submit', 'deleteAGM.submit']);
  }
};
</script>


