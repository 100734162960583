<template>
  <div id="app">
    <input type="file" @change="onChange" />
  </div>
</template>

<script>
import FlipTestRunImport from '@/services/FlipTestRunImport';

export default {
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        FlipTestRunImport(this.file).catch((e) => {
          alert(e.message);
        });
      }
    },
  },
};
</script>
