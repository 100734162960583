<template>
  <b-card class="page-tests" header="Test Runs">
    <b-list-group>
      <b-list-group-item
        v-for="test in tests"
        :key="test.id"
        class="d-flex justify-content-between align-items-center"
        :variant="test.active ? 'success' : null"
      >
        <div>
          <span class="test-description">{{ test.description }}</span>
          <span class="test-description" v-if="test.codename">
            (SMS Code: {{ test.codename }})</span
          >

          <p v-if="test.max_speed" class="test-description mt-4 mb-0">
            Max Speed: {{ test.max_speed }}
          </p>
          <p v-if="test.max_healthy_speed" class="test-description mb-0">
            Max Healthy Speed: {{ test.max_healthy_speed }}
          </p>
          <p v-if="test.min_healthy_speed" class="test-description mb-0">
            Min Healthy Speed: {{ test.min_healthy_speed }}
          </p>
        </div>
        <div class="d-flex align-items-center test-actions">
          <b-button-group size="sm" class="mr-2">
            <b-button variant="link" @click="downloadRun(test)">
              Download Sheet
            </b-button>

            <b-button variant="info" @click="goToTestAgms(test.id)">
              Agms
            </b-button>

            <b-button variant="success " @click="clone(test.id)">
              Clone
            </b-button>

            <b-button variant="danger" @click="deleteTest(test.id)"
              >Delete</b-button
            >
          </b-button-group>
          <span class="mr-2">{{ parseDate(test.created) }}</span>
        </div>
      </b-list-group-item>
    </b-list-group>
    <small class="text-muted">Only the most recent test can be active</small>

    <b-row class="text-center mt-4">
      <b-col>
        <b-button
          variant="success"
          :disabled="processing || loading"
          @click="addNewTest"
          >Add Run</b-button
        ><br />
        <small class="text-muted"
          >Deactivates any active runs for this pipeline</small
        >
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { parse } from 'date-fns';

import CreateTestRunExport from '@/services/CreateTestRunExport';
import { EventBus, db } from '@/main';
import keyBy from 'lodash/keyBy';

export default {
  data() {
    return {
      processing: false,
    };
  },
  computed: {
    ...mapGetters(['loading', 'pipeline', 'tests']),
  },
  methods: {
    addNewTest() {
      EventBus.$emit('createTestModal.open');
    },
    parseDate(d) {
      return moment(d).format('MM/DD/YYYY');
    },
    deleteTest(id) {
      this.$store.dispatch('deleteTest', id);
    },

    goToTestAgms(test_id) {
      this.$router.push(`tests/${test_id}/agms`);
    },

    async clone(test_id) {
      const data = prompt(
        'Please enter a date time in the format yyyy-MM-dd HH:mm' +
          ' (i.e. 2024-01-02 20:12) and I will clone the test and all' +
          ' passages & weld counts until that time in a new test run.'
      );

      const date = parse(data, 'yyyy-MM-dd HH:mm', new Date());
      if (date.toString() === 'Invalid Date') {
        alert(
          'That format is invalid. Please try again with yyyy-MM-dd HH:mm - use the exact number of characters and 24-hour time'
        );
        return;
      }

      this.$store.dispatch('clonePipelineTestUpToDate', {
        pipe_id: this.pipeline.id,
        test_id: test_id,
        timestamp: date.getTime(),
      });
    },
    async downloadRun(test) {
      // first fetch this tests passages and agms
      // This should be moved to vuex eventually
      const passages = await db
        .collection(`pipelines/${this.pipeline.id}/tests/${test.id}/passages`)
        .get()
        .then(({ docs }) => docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      const agms = await db
        .collection(`pipelines/${this.pipeline.id}/tests/${test.id}/agms`)
        .orderBy('order_reference', 'asc')
        .get()
        .then(({ docs }) => docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      const passageMap = keyBy(passages, 'agm_id');

      // console.log('PIPELINE', this.pipeline, this.pipeline.excel_upload.length);
      CreateTestRunExport(
        `${this.pipeline.name.replace(/ /g, '_')} ${test.description.replace(
          / /g,
          '_'
        )}`,
        agms.map((agm) => ({ ...agm, passage: passageMap[agm.id] })),
        this.pipeline.description.replace('_', ' '),
        test.description.replace('_', ' '),
        this.pipeline.name.replace('_', ' ')
      ).catch((e) => {
        alert(e.message);
      });
    },
  },
};
</script>
<style>
.page-tests {
}
</style>
