<template>
  <b-modal
    class="deletePipelineModal"
    title="Delete Pipeline"
    centered
    size="lg"
    ref="deletePipelineModal"
    ok-title="Confirm"
    cancel-title="Cancel"
    @open="open"
    @ok="handleOk"
  >
    <b-alert show variant="light"
      >Do you really want to delete this pipeline? Any contractors assigned to
      it will be unassigned first</b-alert
    >
    <b-loading :loading="processing" />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      processing: false,
      initial: null,
    };
  },
  computed: {
    ...mapGetters(['error', 'loading']),
  },
  methods: {
    open(payload) {
      this.resetFormFields();
      this.initial = { ...payload };
      this.$refs.deletePipelineModal.show();
    },
    close() {
      this.$refs.deletePipelineModal.hide();
    },
    validate() {
      this.form.error.name = !this.form.name;
    },
    handleOk(e) {
      e.preventDefault();
      this.onSubmit();
    },
    onSubmit() {
      this.onSubmitForm();
    },
    onCancel() {
      this.close();
    },
    onReset() {
      this.resetFormFields();
    },
    resetFormFields() {
      this.initial = null;
      this.processing = false;
    },
    onSubmitForm(e) {
      e && e.preventDefault();
      if (this.initial && !this.processing && !this.loading) {
        this.processing = true;
        this.$store.dispatch('deletePipeline', this.initial).finally(() => {
          this.processing = false;
          this.close();
        });
      }
    },
  },
  created() {
    this.resetFormFields();
  },
};
</script>
<style>
.deletePipelineModal .modal-header {
  border-bottom: 0 none;
}
.deletePipelineModal .form-control {
  border: solid 1px #ccc;
}
.deletePipelineModal .form-actions .btn {
  min-width: 100px;
}
</style>
