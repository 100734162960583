<template>
  <div>
    <!-- Tabs -->
    <b-tabs v-model="tabIdx" content-class="mt-3">
      <b-tab title="Pipelines" active>
        <Pipelines></Pipelines>
      </b-tab>
      <b-tab lazy title="Calendar">
        <Calendar></Calendar>
      </b-tab>
      <b-tab title="Contractors">
        <Contractors></Contractors>
      </b-tab>
      <b-tab title="Clients">
        <Clients></Clients>
      </b-tab>
      <b-tab title="App">
        <AppEmbed :isOpen="tabIdx === 3"></AppEmbed>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Pipelines from './Pipelines';
import Contractors from './Contractors';
import Clients from './Clients';
import AppEmbed from './AppEmbed';
import Calendar from './Calendar.vue';

export default {
  components: {
    Pipelines,
    Contractors,
    Clients,
    AppEmbed,
    Calendar,
  },
  data() {
    return {
      tabIdx: 0,
    };
  },
};
</script>
