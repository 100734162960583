<template>
  <b-breadcrumb :items="items"></b-breadcrumb>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      'error',
      'loading',
      'pipeline',
      'test',
    ]),
    items() {

      const { pipeline_id, test_id } = this.$route.params
      // const { name } = this.$route

      const items = [
        {
          text: 'Home',
          to: { name: 'Home' }
        }
      ]
      if (this.pipeline && pipeline_id) {
        items.push({
          text: this.pipeline.name,
          to: '/pipelines/' + this.pipeline.id
        })
        if (this.test && test_id) {
          items.push({
            text: 'Tests',
            to: '/pipelines/' + this.pipeline.id + '/tests'
          })
          items.push({
            text: this.test.description,
          })
        }
      }

      return items
    }
  }
};
</script>
<style>
</style>
