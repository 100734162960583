<template>
  <b-card class="page-pipelines" header="Pipelines">
    <b-list-group>
      <b-list-group-item v-for="pipeline in pipelinesActive" :key="pipeline.id">
        <span class="pipeline-name"
          >{{ pipeline.name }}
          {{ pipeline.codename ? `(${pipeline.codename})` : '' }}</span
        >
        <div class="pipeline-actions text-center my-2">
          <b-button
            target="_blank"
            size="sm"
            :href="`${dataFeedUrl}/${pipeline.id}/${pipeline.pipe_token}`"
            variant="outline-primary"
            :disabled="processing || loading"
            >Dataviewer</b-button
          >

          <b-button
            target="_blank"
            size="sm"
            :href="`https://staging-dataviewer--pr-55-ykdlfo0r.web.app/${pipeline.id}/${pipeline.pipe_token}`"
            variant="outline-secondary"
            :disabled="processing || loading"
            >Dataviewer (beta)</b-button
          >

          <b-button
            @click="editNotified(pipeline)"
            size="sm"
            variant="clear"
            :disabled="processing || loading"
            >Notifications</b-button
          >
          <b-button
            size="sm"
            @click="assignContractors(pipeline)"
            variant="success"
            :disabled="processing || loading"
            >Contractors</b-button
          >
          <b-button
            size="sm"
            @click="gotoAGMs(pipeline)"
            variant="info"
            :disabled="processing || loading"
            >AGM's</b-button
          >
          <b-button
            size="sm"
            @click="gotoTests(pipeline)"
            variant="secondary"
            :disabled="processing || loading"
            >Test Runs</b-button
          >
          <b-button size="sm" variant="primary" @click="editPipeline(pipeline)"
            >Edit</b-button
          >
          <b-button size="sm" @click="deletePipeline(pipeline)" variant="danger"
            >Delete</b-button
          >
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-row class="text-center mt-4">
      <b-col>
        <b-button
          variant="info"
          :disabled="processing || loading"
          @click="addNewPipeline"
          >Add New Pipeline</b-button
        >
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex';

import { EventBus } from '@/main';

export default {
  data() {
    return {
      processing: false,
      dataFeedUrl: process.env.VUE_APP_DATA_FEED_URL,
    };
  },
  computed: {
    ...mapGetters(['loading', 'pipelines']),
    pipelinesActive() {
      return this.pipelines.filter(({ hidden }) => !hidden);
    },
  },
  methods: {
    addNewPipeline() {
      EventBus.$emit('createPipelineModal.open');
    },
    deletePipeline(pipeline) {
      EventBus.$emit('deletePipelineModal.open', pipeline);
    },
    gotoAGMs(pipeline) {
      this.$router.push('/pipelines/' + pipeline.id + '/agms');
    },
    gotoTests(pipeline) {
      this.$router.push('/pipelines/' + pipeline.id + '/tests');
    },
    assignContractors(pipeline) {
      EventBus.$emit('assignContractorModal.open', pipeline);
    },
    editNotified(pipeline) {
      EventBus.$emit('addNotifiedModal.open', pipeline);
    },
    editPipeline(pipeline) {
      EventBus.$emit('createPipelineModal.open', pipeline);
    },

    fileUploaded({ id }, evt) {
      this.$store.dispatch('uploadExcelSheet', { file: evt, pipe_id: id });
    },
  },
};
</script>
<style>
.page-pipelines .pipeline-actions .btn {
  margin-left: 0.5rem;
}
</style>
