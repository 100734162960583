<template>
  <div class="wrapper">
    <div class="auth-panel">
      <page-content></page-content>
    </div>
  </div>
</template>
<script>
import PageContent from './content.vue';
export default {
  components: {
    PageContent,
  },
};
</script>
<style>
.auth-panel {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>