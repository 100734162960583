import orderBy from 'lodash/orderBy';

export default {
  error: state => {
    return state.error;
  },
  loading: state => {
    return state.loading;
  },
  isAuthenticated: state => {
    return state.user !== null && state.user !== undefined;
  },
  user: state => {
    return state.user;
  },
  pipelines: (state, getters) => {
    return state.pipelines || [];
  },
  pipeline: state => {
    return state.activePipeline;
  },
  agms: state => {
    return (state.activePipeline && state.activePipelineData.agms) || [];
  },
  pipeAgms: state => {
    return (state.activeTest && state.activeTestAgms) || [];
  },
  phones: state =>
    (state.activePipeline && state.activePipelineData.phones) || [],
  getAGM: state => agmID => {
    return agms.find(agm => agm.id === agmID) || null;
  },
  tests: state => {
    return (
      (state.activePipeline &&
        state.activePipelineData.tests.map(test => ({
          ...test
          // created: test.created && test.created.toDate() || undefined,
        }))) ||
      []
    );
  },
  test: state => {
    return state.activeTest;
  },
  contractors: state => {
    return state.contractors || [];
  },

  clients: state => {
    return state.clients || [];
  },

  pipelineContractors: state => {
    const pipeContractors =
      (state.activePipeline &&
        state.activePipelineData.contractors.map(i => ({ ...i }))) ||
      [];

    return orderBy(pipeContractors, 'created', 'desc');
  }
};
