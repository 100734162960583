<template>
  <b-modal
    class="createPipelineModal"
    :title="title"
    centered
    size="lg"
    ref="createPipelineModal"
    ok-title="Save Pipeline"
    cancel-title="Close"
    @open="open"
    @ok="handleOk"
  >
    <b-alert show variant="light">* denotes a required field</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" class="px-4">
      <b-form-row>
        <b-col>
          <b-form-group label="Pipeline Name *">
            <b-form-input
              v-model="form.name"
              required
              placeholder="X to Y"
              :state="form.name.length > 0"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Pipeline Client *">
            <b-form-select v-model="form.client_id">
              <b-form-select-option :value="null" disabled
                >Please select a client</b-form-select-option
              >
              <b-form-select-option
                v-for="client in clients"
                :value="client.id"
                :key="client.id"
                >{{ client.company_name }} -
                {{ client.email }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Pipeline Description">
            <b-form-textarea v-model="form.description" rows="3" />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
    <b-loading :loading="processing" />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      processing: false,
      isModeCreate: true,
      initial: {
        name: '',
        description: '',
        client_id: null,
      },
      form: {
        name: '',
        description: '',
        client_id: null,
        error: {
          name: false,
        },
        max_speed: null,
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.validate();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['error', 'loading', 'clients']),
    title() {
      return this.isModeCreate ? 'Add Pipeline' : 'Edit Pipeline';
    },
  },
  methods: {
    open(payload) {
      this.initial = {};
      if (payload) {
        Object.keys(payload).forEach((key) => {
          this.initial[key] = payload[key];
        });
        this.isModeCreate = false;
      } else {
        Object.keys(this.initial).forEach((key) => {
          this.initial[key] = '';
        });
        this.isModeCreate = true;
      }
      this.resetFormFields();
      this.$refs.createPipelineModal.show();
    },
    close() {
      this.$refs.createPipelineModal.hide();
    },
    validate() {
      this.form.error.name = !this.form.name;
    },
    hasError() {
      return Object.keys(this.form.error).some((key) => this.form.error[key]);
    },
    handleOk(e) {
      e.preventDefault();
      this.onSubmit();
    },
    onSubmit() {
      this.onSubmitForm();
    },
    onCancel() {
      this.close();
    },
    onReset() {
      this.resetFormFields();
    },
    resetFormFields() {
      this.form = {
        name: '',
        description: '',
        client_id: null,
        error: {
          name: false,
        },
        max_speed: null,
        ...this.initial,
      };
      this.processing = false;
    },
    onSubmitForm(e) {
      e && e.preventDefault();
      if (!this.hasError() && !this.processing && !this.loading) {
        this.processing = true;
        const { error, ...payload } = this.form;
        this.$store
          .dispatch(
            this.isModeCreate ? 'createPipeline' : 'updatePipeline',
            payload
          )
          .finally(() => {
            this.processing = false;
            this.close();
          });
      }
    },
  },
  created() {
    this.resetFormFields();
  },
};
</script>
<style>
.createPipelineModal .modal-header {
  border-bottom: 0 none;
}
.createPipelineModal .form-control {
  border: solid 1px #ccc;
}
.createPipelineModal .form-actions .btn {
  min-width: 100px;
}
</style>
