<template>
  <div class="wrapper">
    <page-nav></page-nav>
    <b-container class="main-panel mt-4">
      <page-breadcrumbs></page-breadcrumbs>
      <page-content></page-content>
    </b-container>
    <div class="modals">
      <create-pipeline-modal ref="createPipelineModal" />
      <delete-pipeline-modal ref="deletePipelineModal" />
      <create-contractor-modal ref="createContractorModal" />
      <delete-contractor-modal ref="deleteContractorModal" />
      <assign-contractor-modal ref="assignContractorModal" />
      <create-client-modal ref="createClientModal" />
      <delete-client-modal ref="deleteClientModal" />
      <add-notified-modal ref="addNotifiedModal" />
    </div>
  </div>
</template>
<script>
import { EventBus, db } from '@/main';

// Layout
import PageBreadcrumbs from './breadcrumbs.vue';
import PageNav from './nav.vue';
import PageContent from './content.vue';

// Modals
import CreatePipelineModal from '../../modals/CreatePipelineModal';
import DeletePipelineModal from '../../modals/DeletePipelineModal';
import CreateContractorModal from '../../modals/CreateContractorModal';
import DeleteContractorModal from '../../modals/DeleteContractorModal';
import AssignContractorModal from '../../modals/AssignContractorModal';
import CreateClientModal from '../../modals/CreateClientModal';
import DeleteClientModal from '../../modals/DeleteClientModal';
import AddNotifiedModal from '../../modals/AddNotifiedModal';

export default {
  components: {
    PageNav,
    PageBreadcrumbs,
    PageContent,
    CreatePipelineModal,
    DeletePipelineModal,
    CreateContractorModal,
    DeleteContractorModal,
    AssignContractorModal,
    CreateClientModal,
    DeleteClientModal,
    AddNotifiedModal
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('setPipelinesRef', {
        ref: db.collection('pipelines').where('hidden', "==", false),
        callback: () => {
          vm.projectsReady = true;
        }
      });

      vm.$store.dispatch('setContractorsRef', {
        ref: db.collection('contractors'),
        callback: () => {}
      });

      vm.$store.dispatch('setClientsRef', {
        ref: db.collection('clients'),
        callback: () => {}
      });
    });
  },
  mounted() {
    EventBus.$on('createPipelineModal.open', payload => {
      if (this.$refs.createPipelineModal) {
        this.$refs.createPipelineModal.open(payload);
      }
    });
    EventBus.$on('deletePipelineModal.open', payload => {
      if (this.$refs.deletePipelineModal) {
        this.$refs.deletePipelineModal.open(payload);
      }
    });
    EventBus.$on('createContractorModal.open', payload => {
      if (this.$refs.createContractorModal) {
        this.$refs.createContractorModal.open(payload);
      }
    });
    EventBus.$on('deleteContractorModal.open', payload => {
      if (this.$refs.deleteContractorModal) {
        this.$refs.deleteContractorModal.open(payload);
      }
    });
    EventBus.$on('assignContractorModal.open', payload => {
      if (this.$refs.assignContractorModal) {
        this.$refs.assignContractorModal.open(payload);
      }
    });
    EventBus.$on('addNotifiedModal.open', payload => {
      if (this.$refs.addNotifiedModal) {
        this.$refs.addNotifiedModal.open(payload);
      }
    });
    EventBus.$on('createClientModal.open', payload => {
      if (this.$refs.createClientModal) {
        this.$refs.createClientModal.open(payload);
      }
    });
    EventBus.$on('deleteClientModal.open', payload => {
      if (this.$refs.deleteClientModal) {
        this.$refs.deleteClientModal.open(payload);
      }
    });
  },
  beforeDestroy() {
    EventBus.$off([
      'createPipelineModal.open',
      'deletePipelineModal.open',
      'createContractorModal.open',
      'deleteContractorModal.open',
      'assignContractorModal.open',
      'createClientModal.open',
      'deleteClientModal.open'
    ]);
  }
};
</script>
