<template>
  <div class="panel login-panel">
    <div class="content-container">
      <b-card title="Maxwell Pipeline Tracking" class="login-panel-content">
        <b-form @submit.prevent>
          <label class="mb-4">Login</label>
          <b-form-group>
            <b-form-input type="text" placeholder="Email" v-model="form.email">
            </b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-input
              type="password"
              placeholder="Password"
              v-model="form.password"
            >
            </b-form-input>
          </b-form-group>

          <p v-show="error" class="error text-danger">{{ error }}</p>

          <b-row class="text-center my-2">
            <b-col>
              <b-button
                @click="onSubmit"
                variant="primary"
                :disabled="hasError() || loading"
                >Login</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
        error: {
          email: true,
          password: true,
        },
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.validate();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['error', 'loading']),
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (!this.hasError()) {
        const { email, password } = this.form;
        this.$store.dispatch('userSignIn', { email, password });
      }
    },
    validate() {
      this.form.error.email = !this.form.email;
      this.form.error.password = !this.form.password;
    },
    hasError() {
      return Object.keys(this.form.error).some((key) => this.form.error[key]);
    },
  },
};
</script>
<style>
.login-panel {
  min-width: 360px;
}
.login-panel .card-title {
  text-align: center;
}
.login-panel .login-panel-content {
  padding: 20px 20px 0;
  box-shadow: 0 2px 6px rgba(204, 197, 185, 0.5);
}
.login-panel .login-panel-content form {
  max-width: 300px;
  margin: 30px auto;
}
.login-panel .login-panel-content .btn {
  text-transform: initial;
  height: 40px;
  box-shadow: 0 0 4px 2px #dedede;
}
.login-panel .login-panel-content .btn img {
  margin-right: 25px;
}
</style>