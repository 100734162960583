<template>
  <div>
    <FullCalendar :options="calendarOptions" />
  </div>
</template>

<script>
import '@fullcalendar/core/vdom'; // solves problem with Vite

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { db } from '@/main';

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },

  created() {
    db.collection('/allTests').onSnapshot(({ docs }) => {
      this.events = docs.map((doc) => ({
        title: `${doc.data().pipeline.name}: ${doc.data().description}`,
        date: doc.data().created,
        url: `${process.env.VUE_APP_DATA_FEED_URL}/${doc.data().pipeline.id}/${
          doc.data().pipeline.pipe_token
        }/${doc.id}`,
      }));
    });
  },

  computed: {
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',

        events: this.events,
      };
    },
  },
  data() {
    return {
      events: [],
    };
  },
};
</script>
<style>
.fc-event-time,
.fc-event-title {
  padding: 0 1px;
  white-space: normal;
}
</style>
