<template>
  <div>
    <b-modal
      class="addNotifiedModal"
      size="lg"
      ref="addNotifiedModal"
      ok-title="Save"
      hide-footer
      @hidden="close"
      @open="open"
      @ok="handleOk"
    >
      <template v-if="pipeline" v-slot:modal-title>
        {{ pipeline.name }} <br />
        <small> SMS Notified </small>
      </template>
      <div v-if="loading" class="p-4 text-center">
        <b-spinner></b-spinner>
      </div>
      <div>
        <b-form-tags
          placeholder="Add Phone Numbers"
          v-model="phones"
          input-type="tel"
          :tag-validator="validateNumber"
          class="mb-2"
        >
        </b-form-tags>
      </div>

      <!-- Include auto textarea copy tool -->
      <b-form-group label="Copy / Paste Phone Number Extractor">
        <b-textarea v-model="copyPasteText"></b-textarea>
      </b-form-group>

      <div class="text-center">
        <b-button-group>
          <b-button @click="phones = []" variant="danger">Clear All</b-button>

          <b-button
            v-if="extractorPhones.length > 0"
            @click="importFromExtractor"
            variant="info"
            >Import from extractor and update</b-button
          >
          <b-button @click="handleOk" variant="outline-warning">
            Update
          </b-button>
        </b-button-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { db } from '@/main';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF
} from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
export default {
  components: {},
  data() {
    return {
      contractor: null,
      pipeline: null,
      phones: [],

      copyPasteText: ''
    };
  },
  computed: {
    pipelineContractors() {
      return this.$store.getters.pipelineContractors;
    },
    pipelineContractorsMap() {
      return keyBy(this.pipelineContractors, 'contractor_id');
    },
    pipelineMap() {
      return keyBy(this.pipelines, 'id');
    },
    pipelines() {
      return this.$store.getters.pipelines;
    },
    loading() {
      return this.$store.getters.loading;
    },
    initialPhones() {
      return this.$store.getters.phones;
    },

    extractorPhones() {
      return (this.copyPasteText || '')
        .split(/(\s+)/)
        .filter(word => this.validateNumber(word));
    }
  },
  methods: {
    validateNumber(rawNum) {
      try {
        const number = phoneUtil.parseAndKeepRawInput(rawNum, 'US');
        return phoneUtil.isValidNumber(number);
      } catch (err) {
        return false;
      }
    },
    open(pipeline) {
      this.pipeline = pipeline;
      this.$refs.addNotifiedModal.show();
      this.$store.dispatch('setActivePipeline', {
        id: pipeline.id,
        callback: () => {}
      });
    },
    close() {
      this.$store.dispatch('unsetActivePipeline', {
        callback: () => {}
      });
    },
    assign(contractor_id) {},
    unassign(pipe_contractor_id) {
      this.$store.dispatch('unAssignContractor', {
        pipe_contractor_id,
        pipe_id: this.pipeline.id
      });
    },
    handleOk() {
      this.$store.dispatch(
        'setNotified',
        this.phones.map(phone => {
          const number = phoneUtil.parseAndKeepRawInput(phone, 'US');
          return phoneUtil.format(number, PNF.E164);
        })
      );
    },

    importFromExtractor() {
      // console.log(this.extractorPhones);
      this.phones = [...this.phones, ...this.extractorPhones];

      this.copyPasteText = '';

      this.handleOk();
    }
  },
  mounted() {},
  watch: {
    initialPhones(phones) {
      this.phones = [...phones.map(({ phone_number }) => phone_number)];
    }
  }
};
</script>


