<template>
  <div>
    <b-modal
      class="assignContractorModal"
      size="lg"
      ref="assignContractorModal"
      ok-only
      ok-title="Close"
      @hidden="close"
      @open="open"
      @ok="handleOk"
    >
      <template v-if="pipeline" v-slot:modal-title>
        {{ pipeline.name }} <br />
        <small> Contractors </small>
      </template>
      <div v-if="loading" class="p-4 text-center">
        <b-spinner></b-spinner>
      </div>

      <!-- FILTER -->
      <div v-if="!loading" class="mb-4">
        <b-form-input
          v-model="contractorFilter"
          placeholder="Filter Contractors"
        ></b-form-input>
      </div>
      <b-row v-if="!loading">
        <b-col md="6" sm="12">
          <h5>Assigned</h5>
          <p class="text-muted" v-if="pipelineContractors.length === 0">
            No contractors assigned to this pipeline yet
          </p>
          <b-list-group>
            <b-list-group-item
              v-for="pCont in pipelineContractors"
              :key="pCont.id"
            >
              <div class="d-flex flex-row">
                <b-button
                  @click="unassign(pCont.id)"
                  variant="warning"
                  class="mr-2"
                >
                  -
                </b-button>
                <p class="flex-grow-1">
                  {{ contractorMap[pCont.contractor_id].full_name }}
                </p>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col md="6" sm="12">
          <h5>Not Assigned</h5>
          <p class="text-muted" v-if="unAssignedContractors.length === 0">
            All available contractors assigned to this pipeline
          </p>
          <b-list-group>
            <b-list-group-item
              v-for="cont in unAssignedContractors"
              :key="cont.id"
            >
              <div class="d-flex flex-row">
                <b-button @click="assign(cont.id)" variant="info" class="mr-2">
                  +
                </b-button>
                <p class="flex-grow-1">
                  {{ cont.full_name }}
                </p>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { db } from '@/main';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';

export default {
  components: {},
  data() {
    return {
      contractor: null,
      pipeline: null,
      contractorFilter: ''
    };
  },
  computed: {
    pipelineContractors() {
      return sortBy(
        this.$store.getters.pipelineContractors,
        ({ contractor }) => contractor.full_name
      ).filter(({ contractor }) => {
        // first name
        return (
          !this.contractorFilter ||
          contractor.full_name
            .toLowerCase()
            .includes(this.contractorFilter.toLowerCase())
        );
      });
    },
    pipelineContractorsMap() {
      return keyBy(this.pipelineContractors, 'contractor_id');
    },
    pipelineMap() {
      return keyBy(this.pipelines, 'id');
    },
    pipelines() {
      return this.$store.getters.pipelines;
    },
    contractors() {
      return sortBy(this.$store.getters.contractors, 'full_name');
    },
    unAssignedContractors() {
      return this.contractors
        .filter(contractor => !this.pipelineContractorsMap[contractor.id])
        .filter(contractor => {
          // first name
          return (
            !this.contractorFilter ||
            contractor.full_name
              .toLowerCase()
              .includes(this.contractorFilter.toLowerCase())
          );
        });
    },
    contractorMap() {
      return keyBy(this.contractors, 'id');
    },
    loading() {
      return this.$store.getters.loading;
    }
  },
  methods: {
    open(pipeline) {
      this.pipeline = pipeline;
      this.$refs.assignContractorModal.show();
      this.$store.dispatch('setActivePipeline', {
        id: pipeline.id,
        callback: () => {}
      });
    },
    close() {
      this.$store.dispatch('unsetActivePipeline', {
        callback: () => {}
      });
    },
    assign(contractor_id) {
      this.$store.dispatch('assignContractor', {
        contractor_id,
        pipe_id: this.pipeline.id
      });
    },
    unassign(pipe_contractor_id) {
      this.$store.dispatch('unAssignContractor', {
        pipe_contractor_id,
        pipe_id: this.pipeline.id
      });
    },
    handleOk() {}
  }
};
</script>


