<template>
  <b-modal
    class="createAGMModal"
    :title="title"
    centered
    size="lg"
    ref="createAGMModal"
    ok-title="Save Marker"
    cancel-title="Close"
    @open="open"
    @ok="handleOk"
  >
    <b-alert show variant="light">* denotes a required field</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" class="px-4">
      <b-form-row>
        <b-col>
          <b-form-group label="AGM # *">
            <b-form-input
              v-model="form.marker_mile_number"
              required
              type="number"
              no-wheel
              number
              placeholder=""
              :state="
                form.marker_mile_number !== null && form.marker_mile_number >= 0
              "
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Site Description">
            <b-form-textarea v-model="form.site_description" rows="3" />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Run Distance (ft) *">
            <b-form-input
              v-model="form.run_distance"
              required
              placeholder=""
              :state="parseInt(form.run_distance) >= 0"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="MP *">
            <b-form-input
              v-model="form.mp"
              required
              placeholder=""
              :state="form.mp.length > 0"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Diameter (Inches) *">
            <b-form-input
              v-model="form.diameter"
              required
              placeholder=""
              :state="form.diameter.length > 0"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Latitude">
            <b-form-input v-model="form.latitude" required placeholder="" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Longitude">
            <b-form-input v-model="form.longitude" required placeholder="" />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Description of Location">
            <b-form-textarea
              v-model="form.description_of_location"
              required
              rows="3"
              :state="form.description_of_location.length > 0"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="SMS Notifications Enabled">
            <b-form-checkbox v-model="form.notifications_enabled">
              SMS Notifications
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
    <b-loading :loading="processing" />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import isNil from 'lodash/isNil';
const initalData = {
  marker_mile_number: null,
  site_description: '',
  notifications_enabled: false,
  run_distance: '',
  mp: '',
  diameter: '',
  latitude: '',
  longitude: '',
  description_of_location: '',
};
export default {
  data() {
    return {
      processing: false,
      isModeCreate: true,
      initial: {
        ...initalData,
      },
      form: {
        marker_mile_number: null,
        site_description: '',
        notifications_enabled: false,
        run_distance: '',
        mp: '',
        diameter: '',
        latitude: '',
        longitude: '',
        description_of_location: '',
        error: {
          marker_mile_number: false,
          run_distance: false,
          mp: false,
          diameter: false,
          description_of_location: false,
        },
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.validate();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['error', 'loading']),
    title() {
      return this.isModeCreate ? 'Add AGM' : 'Edit AGM';
    },
  },
  methods: {
    open(payload) {
      this.initial = {
        ...initalData,
      };
      if (payload) {
        Object.keys(payload).forEach((key) => {
          this.initial[key] = payload[key];
        });
        this.isModeCreate = false;
      } else {
        this.isModeCreate = true;
      }
      this.resetFormFields();
      this.$refs.createAGMModal.show();
    },
    close() {
      this.$refs.createAGMModal.hide();
    },
    validate() {
      this.form.error.marker_mile_number = isNil(this.form.marker_mile_number);
      this.form.error.run_distance =
        isNil(this.form.run_distance) || this.form.run_distance < 0;
      this.form.error.mp = !this.form.mp;
      this.form.error.diameter = !this.form.diameter;
      this.form.error.description_of_location =
        !this.form.description_of_location;
    },
    hasError() {
      return Object.keys(this.form.error).some((key) => this.form.error[key]);
    },
    handleOk(e) {
      e.preventDefault();
      this.onSubmit();
    },
    onSubmit() {
      this.onSubmitForm();
    },
    onCancel() {
      this.close();
    },
    onReset() {
      this.resetFormFields();
    },
    resetFormFields() {
      this.form = {
        marker_mile_number: '',
        site_description: '',
        notifications_enabled: false,
        run_distance: '',
        mp: '',
        diameter: '',
        latitude: '',
        longitude: '',
        description_of_location: '',
        error: {
          marker_mile_number: false,
          run_distance: false,
          mp: false,
          diameter: false,
          description_of_location: false,
        },
        ...this.initial,
      };
      this.processing = false;
    },
    onSubmitForm(e) {
      e && e.preventDefault();
      if (!this.hasError() && !this.processing && !this.loading) {
        // this.processing = true
        const { error, ...payload } = this.form;
        this.$emit(this.isModeCreate ? 'create' : 'update', {
          ...payload,
        });
      }
    },
  },
  created() {
    this.resetFormFields();
  },
};
</script>
<style>
.createAGMModal .modal-header {
  border-bottom: 0 none;
}
.createAGMModal .form-control {
  border: solid 1px #ccc;
}
.createAGMModal .form-actions .btn {
  min-width: 100px;
}
</style>
