import Excel from 'exceljs';
import FileSaver from 'file-saver';

const FlipTestRunImport = async (file) => {
    const buffer = await file.arrayBuffer();
    const workbook = new Excel.Workbook();
    const worksheet = await workbook.xlsx.load(buffer);
    const activeSheet = worksheet.worksheets[0]
    const firstRowFlip = 12;
    let agmRowValues = [];

    activeSheet.eachRow(function (row, rowNumber) {
        if (rowNumber >= firstRowFlip && row.getCell(4).value != null) {
            agmRowValues.push(row.values)
        }
    });

    agmRowValues.reverse()

    const largestRunDist = agmRowValues[0][3]
    agmRowValues.forEach((agm, idx) => {
        if (agm[2].includes('D/S') || agm[24].includes('D/S')) {
            agm[2] = agm[2].replace('D/S', 'U/S')
            agm[24] = agm[24].replace('D/S', 'U/S')
        } else if (agm[2].includes('U/S') || agm[24].includes('U/S')) {
            agm[2] = agm[2].replace('U/S', 'D/S')
            agm[24] = agm[24].replace('U/S', 'D/S')
        }
    })
    agmRowValues.forEach((agm, idx) => {
        const row = activeSheet.getRow(firstRowFlip + idx);
        row.eachCell(function (cell, cellNumber) {
            if (cellNumber == 2 || cellNumber == 4 || cellNumber == 24 || cellNumber == 23) {
                row.getCell(cellNumber).value = agm[cellNumber]
                row.getCell(cellNumber).fill = null
            }
            if (cellNumber == 3) {
                row.getCell(cellNumber).value = largestRunDist - agm[cellNumber]
            }
        })
    });

    activeSheet.eachRow(function (row, rowNumber) {
        if (rowNumber >= firstRowFlip && row.getCell(4).value != null) {
            row.getCell(5).value = {
                formula: `C${rowNumber}/5280`
            }
            if (rowNumber > firstRowFlip) {
                row.getCell(6).value = {
                    formula: `E${rowNumber} - E${rowNumber - 1}`
                }
            }
        }
    });

    const runName = activeSheet.getRow(9).getCell(4).value
    activeSheet.getRow(9).getCell(4).value = runName.replace(/TO/g, "FROM")

    const fileName = activeSheet.getRow(9).getCell(4).value


    const d = await workbook.xlsx.writeBuffer();
    const blob = new Blob([d], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });
    FileSaver.saveAs(blob, `${fileName}-flipped.xlsx`);
};

export default FlipTestRunImport;