<template>
  <b-modal
    class="createClientModal"
    centered
    :title="title"
    size="lg"
    ref="createClientModal"
    ok-title="Save Client"
    cancel-title="Close"
    @open="open"
    @ok="handleOk"
  >
    <b-alert show variant="light">* denotes a required field</b-alert>

    <b-form @submit="onSubmit" @reset="onReset" class="px-4">
      <b-form-row>
        <b-col>
          <b-form-group
            :invalid-feedback="formErrors.company_name || ''"
            :state="!formErrors.company_name"
            label="Company Name *"
          >
            <b-form-input v-model="form.company_name" required placeholder="" />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group
            :state="!formErrors.email"
            :invalid-feedback="formErrors.email || ''"
            label="Email *"
          >
            <b-form-input
              v-model="form.email"
              autocomplete="new-username"
              type="email"
              required
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- <div class="text-center" v-if="!isModeCreate">
        <b-form-checkbox v-model="shouldSetPassword">
          Update Password
        </b-form-checkbox>
      </div> -->
      <!-- Difficult to update password so disabling this option for now -->

      <b-collapse id="collapse-4" v-model="shouldSetPassword" class="mt-2">
        <b-form-row>
          <b-col>
            <b-form-group
              :invalid-feedback="formErrors.password || ''"
              :state="!formErrors.password"
              label="Password *"
            >
              <b-form-input
                type="password"
                v-model="form.password"
                autocomplete="new-password"
                required
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              :state="!formErrors.password"
              label="Confirm Password *"
            >
              <b-form-input
                v-model="form.confirm_password"
                type="password"
                autocomplete="confirm-password"
                required
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-collapse>
    </b-form>

    <b-loading :loading="processing" />
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

const defaultForm = {
  company_name: '',
  email: '',
  password: '',
  confirm_password: '',
};

const defaultFormErrors = {
  company_name: null,
  email: null,
  password: null,
};

export default {
  components: {},
  data() {
    return {
      processing: false,
      isModeCreate: true,
      shouldSetPassword: true,
      initial: {
        company_name: '',
        email: '',
        password: '',
      },
      form: {
        ...defaultForm,
      },
      formErrors: {
        ...defaultFormErrors,
      },
    };
  },
  watch: {
    form: {
      handler() {
        this.validate();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['error', 'loading']),
    title() {
      return this.isModeCreate ? 'Add Client' : 'Edit Client';
    },
  },
  methods: {
    open(payload) {
      if (payload) {
        Object.keys(payload).forEach((key) => {
          this.initial[key] = payload[key];
        });
        this.isModeCreate = false;
        this.shouldSetPassword = false;
      } else {
        Object.keys(this.initial).forEach((key) => {
          this.initial[key] = '';
        });
        this.isModeCreate = true;
      }
      this.resetFormFields();
      this.$refs.createClientModal.show();
    },
    close() {
      this.$refs.createClientModal.hide();
    },
    validate() {
      const { company_name, email, password, confirm_password } = this.form;

      this.formErrors = {
        company_name: !company_name && 'Please provide a company name',
        email:
          (!email || !email.validateEmail()) && 'Please enter a valid email',
        password:
          ((!password || password.length < 6) &&
            'Please enter a password of 6 characters at least') ||
          (password !== confirm_password && "Password's don't match"),
      };
    },
    hasError() {
      return Object.keys(this.formErrors).some(
        (key) =>
          this.formErrors[key] && (this.shouldSetPassword || key !== 'password')
      );
    },
    handleOk(e) {
      e.preventDefault();
      this.onSubmit();
    },
    onSubmit() {
      this.onSubmitForm();
    },
    onCancel() {
      this.close();
    },
    onReset() {
      this.resetFormFields();
    },
    resetFormFields() {
      this.form = {
        ...defaultForm,
        ...this.initial,
      };

      this.processing = false;
    },
    onSubmitForm(e) {
      e && e.preventDefault();
      if (!this.hasError() && !this.processing && !this.loading) {
        this.processing = true;
        const { error, ...payload } = this.form;
        this.$store
          .dispatch(
            this.isModeCreate ? 'createClient' : 'updateClient',
            payload
          )
          .finally(() => {
            this.processing = false;
            this.close();
          });
      }
    },
  },
  created() {
    this.resetFormFields();
  },
};
</script>
<style>
.createClientModal .modal-header {
  border-bottom: 0 none;
}
.createClientModal .form-control {
  border: solid 1px #ccc;
}
.createClientModal .form-actions .btn {
  min-width: 100px;
}
</style>